import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// app import
import './App.css';
import AppApolloClient from './common/AppApolloClient';
import Login from './components/Login';
import Logout from './components/Logout';
import ErrorPage from './pages/Error';
import ProtectedRoute from './components/ProtectedRoute';
import UserList from './components/UserList';
import ActiveUserList from './components/ActiveUserList';
import TeamList from './components/TeamList';
import InstituteList from './components/InstituteList';
import Dashboard from './components/Dashboard';

class App extends Component {
  render() {
    return (
      <ApolloProvider client={AppApolloClient}>
        <div className="App">
          <Router history={createBrowserHistory()}>
            <Switch>
              <Route exact path="/logout" component={Logout} />
              <Route exact path="/login" component={Login} />
              <ProtectedRoute exact path="/" component={Dashboard} />
              <ProtectedRoute path="/users/:pagenum" component={UserList} />
              <ProtectedRoute path="/activeusers/:mins" component={ActiveUserList} />
              <ProtectedRoute path="/teams/:pagenum" component={TeamList} />
              <ProtectedRoute
                path="/institutes/:pagenum"
                component={InstituteList}
              />
              <ProtectedRoute exact path="/users" component={UserList} />
              <ProtectedRoute exact path="/teams" component={TeamList} />
              <ProtectedRoute
                exact
                path="/institutes"
                component={InstituteList}
              />
              <Route exact path="/error/:error" component={ErrorPage} />
              <Route component={ErrorPage} />
            </Switch>
          </Router>
        </div>
      </ApolloProvider>
    );
  }
}

export default App;
