import React from 'react';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../common/Header';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formElements: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Logout = props => {
  const history = useHistory();
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Header />
      <main className={styles.content}>
        <div className={styles.appBarSpacer} />
        <Container maxWidth="lg" className={styles.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12} xl={10}>
              <div>Click to log out of Admin portal</div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={styles.submit}
                onClick={() => {
                  window.localStorage.removeItem('jwtToken');
                  window.localStorage.removeItem('refreshToken');
                  history.push('/login');
                }}
              >
                Log out
              </Button>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default Logout;
