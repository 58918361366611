import React, { Fragment, Component, useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Collapse from '@material-ui/core/Collapse';
import { getData } from 'country-list';
import { makeStyles } from '@material-ui/core/styles';

import Title from '../common/Title';
import { Link } from '@material-ui/core';
import Header from '../common/Header';
import Copyright from '../common/Copyright';
import Loading from '../pages/Loading';
import Error from '../pages/Error';
import User from './User';

let QUERY_LIMIT = 10;
let search = {
  name: null,
  uri: null,
  email: null,
  active: null,
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formElements: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

class UserList extends Component {
  _handleChangePage = (event, page) => {
    if (page >= 1) {
      this.props.history.push(`/users/${page}`);
    } else {
      this.props.history.push(`/users`);
    }
  };

  _handleInputChange = event => {
    switch (event.target.name) {
      case 'mobile':
        search.mobile = event.target.value.length ? event.target.value : null;
        break;
      case 'email':
        search.email = event.target.value.length ? event.target.value : null;
        break;
      case 'uri':
        search.uri = event.target.value.length ? event.target.value : null;
        break;
      case 'name':
        search.name = event.target.value.length ? event.target.value : null;
        break;
      case 'active':
        search.active = event.target.value !== null ? event.target.value : null;
        break;
      case 'country':
        search.country = event.target.value !== null ? event.target.value : null;
        break;
      default:
        break;
    }
  };

  _handleChangeRowsPerPage = event => {
    QUERY_LIMIT = parseInt(event.target.value, 10);
    this.props.history.push(`/users`);
  };

  _getQueryVariables = () => {
    const page = this.props.match.params.pagenum ?? 0;
    if (page) {
      const offset = page * QUERY_LIMIT;
      return { offset, limit: QUERY_LIMIT, ...search };
    }
    return { offset: 0, limit: QUERY_LIMIT, ...search };
  };

  render() {
    return (
      <Query query={UserQuery} variables={this._getQueryVariables()}>
        {({ loading, error, data, refetch }) => {
          const styles = useStyles();
          if (loading) return <Loading />;
          if (error) return <div>Error</div>;

          return (
            <div className={styles.root}>
              <Header />
              <main className={styles.content}>
                <div className={styles.appBarSpacer} />
                <Container maxWidth="lg" className={styles.container}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12} xl={10}>
                      <Fragment>
                        <form
                          className={styles.formElements}
                          noValidate
                          autoComplete="off"
                        >
                          <Input
                            name="email"
                            placeholder="Email"
                            defaultValue={search.email}
                            inputProps={{ 'aria-label': 'Search by email' }}
                            onChange={this._handleInputChange}
                            type="text"
                          />
                          <Input
                            name="mobile"
                            placeholder="Mobile Number"
                            defaultValue={search.email}
                            inputProps={{ 'aria-label': 'Search by mobile' }}
                            onChange={this._handleInputChange}
                            type="text"
                          />
                          <Input
                            name="name"
                            placeholder="Name"
                            defaultValue={search.name}
                            inputProps={{ 'aria-label': 'Search by name' }}
                            onChange={this._handleInputChange}
                            type="text"
                          />
                          <Input
                            name="uri"
                            placeholder="URI"
                            defaultValue={search.uri}
                            inputProps={{ 'aria-label': 'Search by URI' }}
                            onChange={this._handleInputChange}
                            type="text"
                          />
                          <br />
                          <FormControl className={styles.formControl}>
                            <InputLabel id="first-select-label">Country</InputLabel>
                            <Select
                              labelId="first-select-label"
                              id="country"
                              name="country"
                              inputProps={{ 'aria-label': 'Search Country' }}
                              defaultValue={search.country}
                              onChange={this._handleInputChange}
                            >
                              <MenuItem value={null}>All</MenuItem>
                              {getData().sort((a, b) => (a.name > b.name)).map(row => (
                                 <MenuItem value={row.code}>{row.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl className={styles.formControl}>
                            <InputLabel id="second-select-label">Status</InputLabel>
                            <Select
                              id="active"
                              name="active"
                              inputProps={{ 'aria-label': 'Search Active' }}
                              defaultValue={search.active}
                              labelId="second-select-label"
                              onChange={this._handleInputChange}
                            >
                              <MenuItem value={null}>All Users</MenuItem>
                              <MenuItem value={true}>Active</MenuItem>
                              <MenuItem value={false}>Unverified</MenuItem>
                            </Select>
                          </FormControl>
                          <Button
                            color="primary"
                            onClick={e => {
                              e.preventDefault();
                              refetch(this._getQueryVariables());
                            }}
                          >
                            Search
                          </Button>
                        </form>
                        <UserListView
                          page={parseInt(
                            this.props.match.params.pagenum ?? 0,
                            10
                          )}
                          handleChangePage={this._handleChangePage}
                          handleChangeRowsPerPage={
                            this._handleChangeRowsPerPage
                          }
                          loading={loading}
                          error={error !== undefined}
                          searchUsers={data.searchUsers}
                        />
                        <Typography variant="caption" color="textSecondary" align="center">
                          {`Status: Unverified email/mobile < Incomplete Profile < Active Account`}
                        </Typography>
                      </Fragment>
                    </Grid>
                  </Grid>
                  <Box pt={4}>
                    <Copyright />
                  </Box>
                </Container>
              </main>
            </div>
          );
        }}
      </Query>
    );
  }
}

const UserListView = ({
  loading,
  error,
  searchUsers,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const [open, setOpen] = useState('');

  if (loading) {
    return <Loading />;
  }

  if (error) return <Error />;

  return (
    <React.Fragment>
      <Title>Total Users: {searchUsers.totalCount}</Title>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Mail</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Through</TableCell>
              <TableCell>Registered</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchUsers.data.map(row => (
              <>
                <TableRow
                  style={{ cursor: 'pointer' }}
                  hover
                  key={row.id}
                  onClick={() => {
                    if (open !== row.id) setOpen(row.id);
                    else setOpen(null);
                  }}
                >
                  <TableCell>{row.id}</TableCell>
                  <TableCell>
                    <Link
                      target="_blank"
                      href={`${process.env.REACT_APP_GT_URL}/feed/?redirect_to=/user/` + row.uri}
                    >
                      {row.firstname} {row.lastname}
                    </Link>
                  </TableCell>
                  <TableCell>{row.mail}</TableCell>
                  <TableCell>{row.mobile}</TableCell>
                  <TableCell>{row.country}</TableCell>
                  <TableCell>
                    {row.auth_type == 0 
                      ? 'Email' 
                      : (row.auth_type == 1 ? 'Mobile' : row.oauth_provider.split('-')[1])}
                  </TableCell>
                  <TableCell>
                    {new Date(parseInt(row.reg_date, 10)).toLocaleString(navigator.language,{ hour12: false, year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: "2-digit"})}
                  </TableCell>
                  <TableCell align="right">
                    {row.status == 0 ? 'Unverified' : row.status == 1 ? 'Incomplete' : 'Active'}
                  </TableCell>
                </TableRow>
                {open === row.id ? (
                  <TableRow>
                    <TableCell colspan={6}>
                      <Collapse hidden={open !== row.id} in={open === row.id}>
                        {<User user={row} />}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                ) : null}
              </>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={searchUsers.totalCount}
          rowsPerPage={QUERY_LIMIT}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
};

const UserQuery = gql`
  query(
    $offset: Int!
    $limit: Int
    $name: String
    $email: String
    $uri: String
    $country: String
    $active: Boolean
  ) {
    searchUsers(
      name: $name
      email: $email
      uri: $uri
      country: $country
      offset: $offset
      limit: $limit
      active: $active
    ) {
      data {
        id
        firstname
        lastname
        oauth_provider
        uri
        auth_type
        mail
        mobile
        status
        reg_date
        show_emblem
        status
        avatar_id
        avatar
        cover_id
        country
        bio
        sport
      }
      totalCount
    }
  }
`;

export default UserList;
