import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import Business from '@material-ui/icons/Business';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Divider from '@material-ui/core/Divider';
import { Link } from 'react-router-dom';

export const mainListItems = (
  <div>
    <ListItem button component={props => <Link to={'/'} {...props} />}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItem>
    <ListItem button component={props => <Link to={'/users'} {...props} />}>
      <ListItemIcon>
        <DirectionsRun />
      </ListItemIcon>
      <ListItemText primary="Users" />
    </ListItem>
    <ListItem button component={props => <Link to={'/teams'} {...props} />}>
      <ListItemIcon>
        <PeopleIcon />
      </ListItemIcon>
      <ListItemText primary="Teams" />
    </ListItem>
    <ListItem
      button
      component={props => <Link to={'/institutes'} {...props} />}
    >
      <ListItemIcon>
        <Business />
      </ListItemIcon>
      <ListItemText primary="Institutes" />
    </ListItem>
    <Divider />
    <ListItem button component={props => <Link to={'/logout'} {...props} />}>
      <ListItemIcon>
        <ExitToApp />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  </div>
);
