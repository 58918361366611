import React, { Component } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import { Chart } from 'react-google-charts';
import Typography from '@material-ui/core/Typography';

import Title from '../common/Title';
import Header from '../common/Header';
import Copyright from '../common/Copyright';
import Loading from '../pages/Loading';
import Error from '../pages/Error';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: 'white',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  clickable: {
    cursor: 'pointer',
    textAlign: 'center',
  },
  hyperlink: {
    textDecoration: 'none',
    fontWeight: 'bold',
  },
}));

class Dashboard extends Component {
  _getQueryVariables = () => {
    return { offset: 0 };
    // return { offset: 0, month: new Date().getMonth() + 1, year: new Date().getFullYear() };
    // return { offset: 0, month: 9, year: 2019 };
  };

  render() {
    return (
      <Query query={DashQuery} variables={this._getQueryVariables()}>
        {({ loading, error, data, refetch }) => {
          const geoChartData = [['Country', 'Users']];
          const dailyUserChartData = [['Date', 'Users']];
          let totalCountries = 0;

          if (!loading) {
            for (let i = 0; i < data.geoUsers.length; i++) {
              geoChartData.push([
                data.geoUsers[i].country,
                parseInt(data.geoUsers[i].count, 10),
              ]);
              if (data.geoUsers[i].count > 0) totalCountries += 1;
            }

            for (let i = 0; i < data.dailyUsersLastThirtyDays.length; i++) {
              let dt = new Date(
                parseInt(data.dailyUsersLastThirtyDays[i].date, 10)
              );
              dailyUserChartData.push([
                dt.toDateString(),
                parseInt(data.dailyUsersLastThirtyDays[i].count, 10),
              ]);
            }
          }

          const styles = useStyles();
          const history = useHistory();
          if (loading) return <Loading />;
          if (error) return <Error />;

          return (
            <div className={styles.root}>
              <Header />
              <main className={styles.content}>
                <div className={styles.appBarSpacer} />
                <Container maxWidth="md" className={styles.container}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      className={styles.clickable}
                      onClick={() => history.push('/users')}
                    >
                      Total Users:{' '}
                      <Title>
                        {Intl.NumberFormat().format(
                          data.searchUsers.totalCount
                        )}
                      </Title>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      className={styles.clickable}
                      onClick={() => history.push('/teams')}
                    >
                      Total Teams:{' '}
                      <Title>
                        {Intl.NumberFormat().format(
                          data.searchTeams.totalCount
                        )}
                      </Title>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      className={styles.clickable}
                      onClick={() => history.push('/institutes')}
                    >
                      Total Institutes:{' '}
                      <Title>
                        {Intl.NumberFormat().format(
                          data.searchInstitutes.totalCount
                        )}
                      </Title>
                    </Grid>
                    <Grid item xs={12} md={3} className={styles.clickable}>
                      Total Followers: <Title>110,000</Title>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      Total Countries: <Title>{totalCountries}</Title>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      Total Sports:{' '}
                      <Title>
                        {Intl.NumberFormat().format(
                          data.searchUsers.sportsCount
                        )}
                      </Title>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      Total Interactions:{' '}
                      <Title>
                        {Intl.NumberFormat().format(
                          data.totalInteractions.count
                        )}
                      </Title>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      Total Page Views:
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Only logged in users
                      </Typography>
                      <Title>
                        {Intl.NumberFormat().format(data.totalSiteVisits.count)}
                      </Title>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      Weekly Interactions:{' '}
                      <Title>
                        {Intl.NumberFormat().format(
                          data.weeklyInteractions.count
                        )}
                      </Title>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      Weekly Page Views:
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        Only logged in users
                      </Typography>
                      <Title>
                        {Intl.NumberFormat().format(
                          data.weeklySiteVisits.count
                        )}
                      </Title>
                    </Grid>
                    { /**
                    <Grid
                      item
                      xs={12}
                      md={3}
                      className={styles.clickable}
                      onClick={() => history.push('/activeusers/60')}
                    >
                      Users active in the last hour:{' '}
                      <Title>
                        {Intl.NumberFormat().format(
                          data.hourlyActive.totalCount
                        )}
                      </Title>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      className={styles.clickable}
                      onClick={() => history.push('/activeusers/1440')}
                    >
                      Daily Active User:{' '}
                      <Title>
                        {Intl.NumberFormat().format(
                          data.dailyActive.totalCount
                        )}
                      </Title>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      className={styles.clickable}
                      onClick={() => history.push('/activeusers/10080')}
                    >
                      Weekly Active Users:{' '}
                      <Title>
                        {Intl.NumberFormat().format(
                          data.weeklyActive.totalCount
                        )}
                      </Title>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      className={styles.clickable}
                      onClick={() => history.push('/activeusers/43200')}
                    >
                      Monthly Active Users:{' '}
                      <Title>
                        {Intl.NumberFormat().format(
                          data.monthlyActive.totalCount
                        )}
                      </Title>
                    </Grid>
                     */}
                  </Grid>
                  <Chart
                    width={'100%'}
                    height={'600px'}
                    chartType="GeoChart"
                    data={geoChartData}
                    options={{
                      title: 'Total registrations worldwide',
                      defaultColor: 'white',
                      colorAxis: {
                        minValue: 0,
                        colors: ['#ffe4c4', '#b36200'],
                      },
                    }}
                    // Note: you will need to get a mapsApiKey for your project.
                    // See: https://developers.google.com/chart/interactive/docs/basic_load_libs#load-settings
                    mapsApiKey="AIzaSyD4dpjn9cAYqgiue3mS-vzYgWPDpt4LIME"
                    rootProps={{ 'data-testid': '1' }}
                  />
                  <Chart
                    width={'100%'}
                    height={'600px'}
                    chartType="BarChart"
                    loader={<div>Loading Chart</div>}
                    data={dailyUserChartData}
                    options={{
                      title: 'Daily registration count in the last thirty days',
                      chartArea: { width: '50%', height: '70%' },
                    }}
                    rootProps={{ 'data-testid': '2' }}
                  />
                  <Grid container justify="center" spacing={3}>
                    <Grid item xs={12}></Grid>
                    <Grid justify="flex-start" item xs={12}>
                      <Title>SOCIAL STATS</Title>
                    </Grid>
                    <Grid item xs={6} md={6} className={styles.clickable}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/globaltalentdotpro"
                        className={styles.hyperlink}
                      >
                        Facebook
                      </a>
                      <Typography variant="subtitle1" gutterBottom>
                        Followers - 78,755
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} className={styles.clickable}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.youtube.com/user/Svensktalang"
                        className={styles.hyperlink}
                      >
                        Youtube
                      </a>
                      <Typography variant="subtitle1" gutterBottom>
                        Subscribers - 18
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} className={styles.clickable}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/swedish-talent-ab/"
                        className={styles.hyperlink}
                      >
                        Linkedin
                      </a>
                      <Typography variant="subtitle1" gutterBottom>
                        Followers - 493
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6} className={styles.clickable}>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.instagram.com/globaltalent.pro/"
                        className={styles.hyperlink}
                      >
                        Instagram
                      </a>
                      <Typography variant="subtitle1" gutterBottom>
                        Followers - 3,552
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button
                    href="https://www.globaltalent.pro/export.php"
                    style={{ display: 'none' }}
                    disabled={true}
                    variant="outlined"
                    color="primary"
                    startIcon={<SaveIcon />}
                  >
                    Export All Users
                  </Button>
                  <Box pt={4}>
                    <Copyright />
                  </Box>
                </Container>
              </main>
            </div>
          );
        }}
      </Query>
    );
  }
}

const DashQuery = gql`
  query($offset: Int!) {
    searchUsers(offset: $offset) {
      data {
        id
      }
      totalCount
      sportsCount
    }
    searchTeams(offset: $offset) {
      data {
        id
      }
      totalCount
    }
    searchInstitutes(offset: $offset) {
      data {
        id
      }
      totalCount
    }
    geoUsers {
      country
      count
    }
    dailyUsersLastThirtyDays {
      date
      count
    }
    weeklyInteractions {
      count
    }
    weeklySiteVisits {
      count
    }
    totalInteractions {
      count
    }
    totalSiteVisits {
      count
    }
    hourlyActive: recentActive(mins: 60) {
      totalCount
    }
    dailyActive: recentActive(mins: 1400) {
      totalCount
    }
    weeklyActive: recentActive(mins: 10080) {
      totalCount
    }
    monthlyActive: recentActive(mins: 43200) {
      totalCount
    }
  }
`;

export default Dashboard;
