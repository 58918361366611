import React, { Fragment, Component, useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Launch from '@material-ui/icons/Launch';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';

import Title from '../common/Title';
import { Link } from '@material-ui/core';
import Header from '../common/Header';
import Copyright from '../common/Copyright';
import Loading from '../pages/Loading';
import Error from '../pages/Error';
import Team from './Team';

let QUERY_LIMIT = 10;
let search = {
  name: null,
  uri: null,
  active: null,
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formElements: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

class TeamList extends Component {
  _handleChangePage = (event, page) => {
    if (page >= 1) {
      this.props.history.push(`/teams/${page}`);
    } else {
      this.props.history.push(`/teams`);
    }
  };

  _handleInputChange = event => {
    switch (event.target.name) {
      case 'uri':
        search.uri = event.target.value.length ? event.target.value : null;
        break;
      case 'name':
        search.name = event.target.value.length ? event.target.value : null;
        break;
      case 'active':
        search.active = event.target.value !== null ? event.target.value : null;
        break;
      default:
        break;
    }
  };

  _handleChangeRowsPerPage = event => {
    QUERY_LIMIT = parseInt(event.target.value, 10);
    this.props.history.push(`/teams`);
  };

  _getQueryVariables = () => {
    const page = this.props.match.params.pagenum ?? 0;
    if (page) {
      const offset = page * QUERY_LIMIT;
      return { offset, limit: QUERY_LIMIT, ...search };
    }
    return { offset: 0, limit: QUERY_LIMIT, ...search };
  };

  render() {
    return (
      <Query query={TeamQuery} variables={this._getQueryVariables()}>
        {({ loading, error, data, refetch }) => {
          const styles = useStyles();
          if (loading) return <Loading />;
          if (error) return <Error />;

          return (
            <div className={styles.root}>
              <Header />
              <main className={styles.content}>
                <div className={styles.appBarSpacer} />
                <Container maxWidth="lg" className={styles.container}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12} xl={10}>
                      <Fragment>
                        <form
                          className={styles.formElements}
                          noValidate
                          autoComplete="off"
                        >
                          <Input
                            name="name"
                            placeholder="Name"
                            defaultValue={search.name}
                            inputProps={{ 'aria-label': 'Search by name' }}
                            onChange={this._handleInputChange}
                            type="text"
                          />
                          <Input
                            name="uri"
                            placeholder="URI"
                            defaultValue={search.uri}
                            inputProps={{ 'aria-label': 'Search by URI' }}
                            onChange={this._handleInputChange}
                            type="text"
                          />
                          <FormControl className={styles.formControl}>
                            <Select
                              id="active"
                              name="active"
                              inputProps={{ 'aria-label': 'Search Active' }}
                              defaultValue={search.active}
                              onChange={this._handleInputChange}
                            >
                              <MenuItem value={null}>All Teams</MenuItem>
                              <MenuItem value={true}>Active</MenuItem>
                              <MenuItem value={false}>InActive</MenuItem>
                            </Select>
                          </FormControl>
                          <Button
                            color="primary"
                            onClick={e => {
                              e.preventDefault();
                              refetch(this._getQueryVariables());
                            }}
                          >
                            Search
                          </Button>
                        </form>
                        <TeamListView
                          page={parseInt(
                            this.props.match.params.pagenum ?? 0,
                            10
                          )}
                          handleChangePage={this._handleChangePage}
                          handleChangeRowsPerPage={
                            this._handleChangeRowsPerPage
                          }
                          loading={loading}
                          error={error !== undefined}
                          searchTeams={data.searchTeams}
                        />
                      </Fragment>
                    </Grid>
                  </Grid>
                  <Box pt={4}>
                    <Copyright />
                  </Box>
                </Container>
              </main>
            </div>
          );
        }}
      </Query>
    );
  }
}

const TeamListView = ({
  loading,
  error,
  searchTeams,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const [open, setOpen] = useState('');
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <React.Fragment>
      <Title>Total Teams: {searchTeams.totalCount}</Title>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>
                URI
                <Launch fontSize="small" />
              </TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Registered</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchTeams.data.map(row => (
              <>
                <TableRow
                  style={{ cursor: 'pointer' }}
                  hover
                  key={row.id}
                  onClick={() => {
                    if (open !== row.id) setOpen(row.id);
                    else setOpen(null);
                  }}
                >
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    <Link
                      target="_blank"
                      href={`${process.env.REACT_APP_GT_URL}/feed/?redirect_to=/team/` + row.uri}
                    >
                      {row.uri}
                    </Link>
                  </TableCell>
                  <TableCell>{row.country}</TableCell>
                  <TableCell>
                    {new Date(parseInt(row.reg_date, 10)).toLocaleString(navigator.language,{ hour12: false, year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: "2-digit"})}
                  </TableCell>
                  <TableCell align="right">
                    {row.status > 0 ? 'Active' : 'InActive'}({row.status})
                  </TableCell>
                </TableRow>
                {open === row.id ? (
                  <TableRow>
                    <TableCell colspan={6}>
                      <Collapse hidden={open !== row.id} in={open === row.id}>
                        {<Team team={row} />}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                ) : null}
              </>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={searchTeams.totalCount}
          rowsPerPage={QUERY_LIMIT}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
};

const TeamQuery = gql`
  query(
    $offset: Int!
    $limit: Int
    $name: String
    $uri: String
    $active: Boolean
  ) {
    searchTeams(
      name: $name
      uri: $uri
      offset: $offset
      limit: $limit
      active: $active
    ) {
      data {
        id
        name
        uri
        status
        reg_date
        status
        avatar_id
        avatar
        cover_id
        sport_id
        institute_id
        sport {
          id
          name
        }
        institute {
          name
          uri
        }
        country
      }
      totalCount
    }
  }
`;

export default TeamList;
