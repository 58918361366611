import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../common/Header';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formElements: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

// loading status
const LoadingPage = () => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Header />
      <main className={styles.content}>
        <div className={styles.appBarSpacer} />
        <Container maxWidth="lg" className={styles.container}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12} xl={10}>
              <div>Loading.....</div>
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  );
};

export default LoadingPage;
