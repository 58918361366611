import React, { Fragment, Component, useState } from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { makeStyles } from '@material-ui/core/styles';

import Title from '../common/Title';
import { Link } from '@material-ui/core';
import Header from '../common/Header';
import Copyright from '../common/Copyright';
import Loading from '../pages/Loading';
import Error from '../pages/Error';
import User from './User';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  formElements: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}));

class ActiveUserList extends Component {
  _getQueryVariables = () => {
    const mins = parseInt(this.props.match.params.mins, 10) ?? 0;
    return { mins };
  };

  render() {
    return (
      <Query query={ActiveUserQuery} variables={this._getQueryVariables()}>
        {({ loading, error, data, refetch }) => {
          const styles = useStyles();
          if (loading) return <Loading />;
          if (error) return <div>Error</div>;

          return (
            <div className={styles.root}>
              <Header />
              <main className={styles.content}>
                <div className={styles.appBarSpacer} />
                <Container maxWidth="lg" className={styles.container}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12} xl={10}>
                      <Fragment>
                        <ActiveUserListView
                          mins={parseInt(
                            this.props.match.params.mins ?? 0,
                            10
                          )}
                          
                          loading={loading}
                          error={error !== undefined}
                          recentActive={data.recentActive}
                        />
                        <Typography variant="caption" color="textSecondary" align="center">
                          {`Status: Unverified email/mobile < Incomplete Profile < Active Account`}
                        </Typography>
                      </Fragment>
                    </Grid>
                  </Grid>
                  <Box pt={4}>
                    <Copyright />
                  </Box>
                </Container>
              </main>
            </div>
          );
        }}
      </Query>
    );
  }
}

const ActiveUserListView = ({
  loading,
  error,
  recentActive
}) => {
  const [open, setOpen] = useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) return <Error />;

  return (
    <React.Fragment>
      <Title>Total Users: {recentActive.totalCount}</Title>
      <Typography variant="caption" display="block" gutterBottom>
          Most active on top
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Mail</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Registered</TableCell>
              <TableCell>Type</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recentActive.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => (
              <>
                <TableRow
                  style={{ cursor: 'pointer' }}
                  hover
                  key={row.id}
                  onClick={() => {
                    if (open !== row.id) setOpen(row.id);
                    else setOpen(null);
                  }}
                >
                  <TableCell>{row.id}</TableCell>
                  <TableCell>
                    <Link
                      target="_blank"
                      href={`${process.env.REACT_APP_GT_URL}/feed/?redirect_to=/user/` + row.uri}
                    >
                      {row.firstname} {row.lastname}
                    </Link>
                  </TableCell>
                  <TableCell>{row.mail}</TableCell>
                  <TableCell>{row.mobile}</TableCell>
                  <TableCell>{row.country}</TableCell>
                  <TableCell>
                    {new Date(parseInt(row.reg_date, 10)).toLocaleString(navigator.language,{ hour12: false, year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: "2-digit"})}
                  </TableCell>
                  <TableCell>
                    {(new Date()) - new Date(parseInt(row.reg_date, 10)) > 1000 * 60 * 60 * 12 ? 'Returning': 'New'}
                  </TableCell>
                  <TableCell align="right">
                    {row.status == 0 ? 'Unverified' : row.status == 1 ? 'Incomplete' : 'Active'}
                  </TableCell>
                </TableRow>
                {open === row.id ? (
                  <TableRow>
                    <TableCell colspan={6}>
                      <Collapse hidden={open !== row.id} in={open === row.id}>
                        {<User user={row} />}
                      </Collapse>
                    </TableCell>
                  </TableRow>
                ) : null}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={recentActive.totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </React.Fragment>
  );
};

const ActiveUserQuery = gql`
  query(
    $mins: Int!
  ) {
    recentActive(
      mins: $mins
    ) {
      data {
        id
        firstname
        lastname
        auth_type
        oauth_provider
        uri
        mail
        mobile
        status
        reg_date
        show_emblem
        status
        avatar_id
        avatar
        cover_id
        country
        bio
        sport
      }
      totalCount
    }
  }
`;

export default ActiveUserList;
