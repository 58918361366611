import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  headers: {
    authorization: `Bearer ${window.localStorage.getItem('jwtToken')}`,
  },
});

const logoutLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    const options = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Authorization': 'Bearer ' + window.localStorage.getItem('refreshToken'),
        "Content-Type": "application/json"
      },
    };

    fetch(process.env.REACT_APP_AUTH_API_URL.replace('login', 'refresh_token'), options)
      .then(response => {
        if (!response.ok) {
          console.log('refreshToken', response.status)
          if (response.status == 401) {
            window.localStorage.removeItem('jwtToken');
            window.localStorage.removeItem('refreshToken');
            setTimeout(() => {
              window.location.assign('/');
            }, 10);
          }
        }
        return response;
      })
      .then(response => response.json())
      .then(res => {
        if (
          res &&
          res.data &&
          res.data.accessToken &&
          typeof res.data.accessToken === 'string' &&
          res.data.accessToken.length > 10
        ) {
          window.localStorage.setItem('jwtToken', res.data.accessToken)
          setTimeout(() => {
            window.location.assign('/');
          }, 0);
        }
      });
   
    // if (networkError.statusCode === 401) Logout();
  }
});

// configure an ApolloClient
const AppApolloClient = new ApolloClient({
  link: logoutLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default AppApolloClient;
