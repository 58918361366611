import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    maxHeight: '100%',
    width: 150,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function Institute({ inst }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.cover}
        image={`${process.env.REACT_APP_GT_URL}/${inst.emblem}`}
      />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5">
            {inst.name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {inst.homepage ? `Homepage: ${inst.homepage}` : null}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            From {inst.country}
          </Typography>
          <Typography variant="subtitle3" color="textSecondary">
            {inst.bio}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
}
