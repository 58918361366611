import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

// error page
const ErrorPage = ({ message }) => {
  return (
    <Fragment>
      <div>Error {message}</div>
      <br />
      <div>
        Try reloading the page to see if the error still exists. If it still
        persists please contact admin.
      </div>
      <br />
      <Button
        type="submit"
        variant="contained"
        color="primary"
        onClick={() => {
          window.location.assign('/');
        }}
      >
        Reload page
      </Button>
    </Fragment>
  );
};

// prop type validation
ErrorPage.propTypes = {
  message: PropTypes.string,
};

// default prop
ErrorPage.defaultProps = {
  message: 'General error',
};

export default ErrorPage;
